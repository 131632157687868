/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';

import { useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import DefaultAvatar from 'assets/svg/default-avatar.svg';

interface Props {
  firstName?: string;
  avatar?: string;
  onLogout?: () => void;
  signingIn?: () => '';
  username?: string;
}

export default function ProfileButton({ avatar, onLogout, username }: Props) {
  const [dropdown, setDropdown] = useState(false);
  const { t } = useTranslation();

  const logout = (e) => {
    e.preventDefault();
    onLogout();
  };

  return (
    <div
      onClick={() => setDropdown(!dropdown)}
      sx={{
        // display: ['none', 'flex', 'flex'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative' as any,
        borderRadius: 3,
        cursor: 'pointer',
        color: 'white',
        zIndex: 1,
        mr: ['22px', '22px', 0],
      }}
    >
      <img
        sx={{
          objectFit: 'cover' as any,
          borderRadius: '50%',
          objectPosition: 'center top',
          width: '40px',
          height: '40px',
          mr: 3,
        }}
        src={avatar || DefaultAvatar}
      />
      <div
        sx={{
          position: (dropdown ? 'fixed' : 'none') as any,
          // zIndex: -1,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        onClick={() => setDropdown(false)}
      />

      <div
        sx={{
          position: 'absolute',
          display: dropdown ? 'block' : 'none',
          right: 0,
          bg: 'textWhite',
          color: 'text',
          top: '100%',
          borderWidth: '1px',
          borderColor: 'primary',
          borderStyle: 'solid',
          minWidth: '150px',
          mt: 1,
          borderRadius: 3,
          textAlign: 'left' as any,
          a: {
            textDecoration: 'none',
            color: 'primary',
            display: 'block',
            py: 2,
            px: 3,
            whiteSpace: 'nowrap',
            ':hover': {
              opacity: 0.8,
            },
          },
        }}
      >
        <Link href={`/authors/${username}`}>{t('Profile')}</Link>
        <Link href="/settings">{t('Settings')}</Link>
        <a href="#" onClick={logout}>
          {t('Log out')}
        </a>
      </div>
    </div>
  );
}
