/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import Logo from '../../assets/svg/logo.svg';
import Link from 'next/link';

const styles = {
  header: {
    width: '100vw',
    position: 'fixed' as any,
    py: 1,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 10,
    variant: 'containers.box',
    bg: 'white',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    variant: 'containers.page',
    height: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  logo: {
    height: ['40px', '50px'],
    bg: 'white',
  },
};

const NavWrapper = ({ children }) => {
  return (
    <>
      <header sx={styles.header}>
        <nav sx={styles.nav}>
          <Link href="/">
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:hover': { cursor: 'pointer' },
              }}
            >
              <img sx={{ ...styles.logo }} src={Logo} alt="Logo" srcSet="" />
              {/* <span sx={{ display: ['none', 'initial'], fontSize: [3, 5], ml: 2 }}>
            notice
          </span> */}
            </div>
          </Link>
          {children}
        </nav>
      </header>
    </>
  );
};

export default NavWrapper;
