/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid, Select } from 'theme-ui';
import { withTranslation } from 'react-i18next';
import Link from 'next/link';
import dayjs from 'dayjs';
import Logo from '../../assets/svg/logo-name.svg';
import { useRouter } from 'next/router';
import SocialLinks from 'components/common/SocialLinks';
import {
  privacyPolicyLink,
  termsOfService,
  aboutNoticeFaxPost,
} from '../../constants/links';
const styles = {
  footer: {
    width: '100vw',
    bg: 'white',
    zIndex: 1,
    pb: ['70px', '70px', '0px'],
    fontSize: ['0.7rem', '0.8rem'],
  },
  linksContainer: {
    a: {
      mx: 3,
      mt: 3,
      ml: [0, 0, 3],
      textDecoration: 'none',
      color: 'primary',
      display: 'block',
    },
  },
  copyright: {
    fontSize: 1,
    textAlign: 'center',
  },
};

const Footer = ({ t }) => {
  const { locale, pathname, asPath, push } = useRouter();

  const mainPages = [
    {
      name: t('Home'),
      path: '/',
    },
    // {
    //   name: t('Categories'),
    //   path: '/categories',
    // },
    {
      name: t('Authors'),
      path: '/authors',
    },
    {
      name: t('About'),
      path: `/posts/${aboutNoticeFaxPost.split('/').pop()}`,
    },
    // {
    //   name: t('Contact'),
    //   path: '/contact',
    // },
  ];

  const otherPages = [
    {
      name: t('Privacy Policy'),
      path: privacyPolicyLink,
    },
    {
      name: t('Terms of Service'),
      path: termsOfService,
    },
    {
      name: t('FAQ'),
      path: '/faq',
    },
    // {
    //   name: t('Support'),
    //   path: '#',
    // },
  ];

  const changeLanguage = ({ target: { value } }) => {
    if (value !== locale) {
      push(pathname, asPath, { locale: value });
    }
  };

  return (
    <footer sx={styles.footer}>
      <Grid
        sx={{ variant: 'containers.page', pb: 0, py: 2 }}
        columns={[1, '2.5fr 1fr 1fr 1fr']}
      >
        <div sx={{ textAlign: ['center', 'left'] }}>
          <img
            sx={{ height: '40px', mt: '20px' }}
            src={Logo}
            alt="Image logo"
          />
          <p>
            {t(
              'NoticeFax is a Pan-African online platform for localized and customized information sharing',
            )}
          </p>
          <SocialLinks />
        </div>
        <div sx={{ ...styles.linksContainer, display: ['none', 'block'] }}>
          {mainPages?.map((page) => (
            <Link key={page.path} href={page.path}>
              {page.name}
            </Link>
          ))}
        </div>
        <div sx={styles.linksContainer}>
          {otherPages?.map((page) => (
            <Link key={page.path} href={page.path}>
              {page.name}
            </Link>
          ))}
        </div>
        <div sx={{ textAlign: ['left', 'right'] }}>
          <h3>{t('Language')}</h3>
          <Select onChange={changeLanguage} value={locale}>
            <option value="en">English</option>
            <option value="fr">Français</option>
          </Select>
          <p sx={{ mt: 1, fontSize: ['0.6rem', '0.7rem', '0.7rem'] }}>
            {t('footer_copyright', { year: dayjs().year() })}
          </p>
        </div>
      </Grid>
    </footer>
  );
};

export default withTranslation()(Footer);
