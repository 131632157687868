/** @jsxRuntime classic */
/** @jsx jsx */
import React, { ReactElement } from 'react';
import { jsx } from 'theme-ui';
import Link from 'next/link';
import DefaultAvatar from 'assets/svg/default-avatar.svg';
interface Props {
  user: any;
}

const UserAvatar = ({ user }: Props): ReactElement => {
  return (
    <div
      sx={{
        position: 'relative',
        display: 'flex',
      }}
    >
      <Link key={user?.username} href={`/authors/${user?.username}`}>
        <div
          sx={{
            height: '40px',
            width: '40px',
            backgroundColor: 'textWhite',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            color: 'primary',
            backgroundImage: `url("${user?.avatarUrl || DefaultAvatar}")`,
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          {user?.notificationsCount > 0 && (
            <Link href="/notifications">
              <div
                sx={{
                  position: 'absolute',
                  top: '6px',
                  right: '-5px',
                  transform: 'translateY(-50%)',
                  fontSize: '10px',
                  backgroundColor: 'textRed',
                  border: '1px solid white',
                  borderRadius: '50%',
                  display: 'flex',
                  height: '20px',
                  width: '20px',
                  color: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {user?.notificationsCount}
              </div>
            </Link>
          )}
        </div>
      </Link>
    </div>
  );
};

export default UserAvatar;
