/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Flex } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { BiCategory } from 'react-icons/bi';
import { BsPeople, BsThreeDotsVertical, BsPencilSquare } from 'react-icons/bs';
import { HiOutlineHome } from 'react-icons/hi';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import { logoutUser } from 'reducers/currentUser/actions';

import UserAvatar from 'components/common/UserAvatar';
import { setAuthModal } from 'reducers/auth/actions';

const MenuItem = ({
  item,
  currentPath,
}: {
  item: {
    icon: any;
    path: string;
    name: string;
  };
  currentPath?: boolean;
}) => {
  return (
    <Link href={item.path}>
      <div
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: currentPath ? 'textBlack' : 'textGray',
          backgroundColor: currentPath ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
          px: 1,
          py: 2,
          height: '100%',
          borderRight: '1px solid rgba(0, 0, 0, 0.09)',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <div>{item.icon}</div>
        <div sx={{ fontSize: '10px' }}>{item.name}</div>
      </div>
    </Link>
  );
};

const AuthItem = ({
  item,
  onClick,
}: {
  item: {
    icon?: any;
    name: string;
  };
  onClick: () => void;
}) => {
  return (
    <div
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'textGray',
        backgroundColor: 'transparent',
        px: 3,
        py: 2,
        height: '100%',
        borderRight: '1px solid rgba(0, 0, 0, 0.09)',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      {item.icon && <div>{item?.icon}</div>}
      <div sx={{ fontSize: '12px', fontWeight: 500 }}>{item.name}</div>
    </div>
  );
};

const MobileMenu = ({ currentUser }) => {
  const { pathname, asPath } = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const popover = usePopoverState({ placement: 'auto-start' });
  const openAuthModal = (auth = 'Login') => {
    dispatch(setAuthModal(auth));
  };

  const items = [
    {
      icon: <HiOutlineHome size="20" />,
      path: '/',
      name: t('Home'),
    },
    {
      icon: <BsPeople size="20" />,
      path: '/authors',
      name: t('Authors'),
    },
    {
      icon: <BiCategory size="20" />,
      path: '/categories',
      name: t('Categories'),
    },
  ];
  const logout = () => {
    logoutUser()(dispatch);
  };

  return (
    <footer
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        minHeight: '50px',
        display: ['flex', 'flex', 'none'],
        zIndex: 1000,
        backgroundColor: 'textWhite',
        boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.09)',
      }}
    >
      {asPath !== '/posts/create' && (
        <div
          sx={{
            position: 'fixed',
            display: ['flex', 'flex', 'none'],
            flexDirection: 'column',
            right: '16px',
            bottom: '80px',
            alignItems: 'center',
          }}
        >
          <Link href="/posts/create">
            <div
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'textBlack',
                color: 'textWhite',
                flexDirection: 'column',
                p: 2,
                borderRadius: '50%',
                height: '48px',
                width: '48px',
              }}
            >
              <BsPencilSquare size="12" />
              <span sx={{ fontSize: '8px', mt: '2px' }}>{t('Post')}</span>
            </div>
          </Link>
        </div>
      )}
      <div
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {items?.map((item) => (
          <MenuItem
            key={item?.path}
            item={item}
            currentPath={pathname === item.path}
          />
        ))}
      </div>
      <div sx={{ py: 2, pl: 2, display: 'flex' }}>
        {currentUser?.firstName ? (
          <>
            <UserAvatar user={currentUser} />
            <PopoverDisclosure
              {...popover}
              style={{
                background: 'none',
                border: 'none',
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <BsThreeDotsVertical />
            </PopoverDisclosure>
            <Popover {...popover} tabIndex={0} aria-label="more-actions">
              <Flex
                sx={{
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.2)',
                  display: 'flex',
                  flexDirection: 'column',
                  a: {
                    textDecoration: 'none',
                    color: 'primary',
                    display: 'block',
                    py: 2,
                    px: 3,
                    whiteSpace: 'nowrap',
                    ':hover': {
                      opacity: 0.8,
                    },
                  },
                }}
              >
                <Link href={`/authors/${currentUser?.username}`}>
                  {t('Profile')}
                </Link>
                <Link href="/settings">{t('Settings')}</Link>
                <a href="#" onClick={logout}>
                  {t('Log out')}
                </a>
              </Flex>
            </Popover>
          </>
        ) : (
          <>
            <AuthItem
              item={{ name: t('Login') }}
              onClick={() => openAuthModal('Login')}
            />
            <AuthItem
              item={{ name: t('Signup') }}
              onClick={() => openAuthModal('Signup')}
            />
          </>
        )}
      </div>
    </footer>
  );
};

export default MobileMenu;
