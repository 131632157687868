/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button, Input } from 'theme-ui';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../assets/svg/search.svg';
import { useRouter } from 'next/router';

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

export default function SearchBar() {
  const [timer, setTimer] = useState(null);
  const [value, setValue] = useState('');
  const { query, push } = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    setValue((query?.keywords as string) || '');
  }, [query]);

  const triggerChange = ({ type = '', keywords = '' } = {}) => {
    push({
      pathname: '/search',
      query: {
        keywords,
        type: type || query?.type || 'post',
      },
    });
  };

  const handleChange = ({ target }) => {
    clearTimeout(timer);

    setValue(target.value);

    setTimer(
      setTimeout(
        () => triggerChange({ keywords: target.value }),
        WAIT_INTERVAL,
      ),
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer);
      triggerChange({
        keywords: value,
      });
    }
  };

  // const handleSearch = (e) => {
  //   triggerChange({
  //     keywords: value,
  //   });
  // };

  // const onSearch = () => {
  //   push({
  //     pathname: '/search',
  //     query: {
  //       keywords: value,
  //     },
  //   });
  // };

  return (
    <div
      sx={{
        bg: 'white',
        borderRadius: 5,
        display: 'flex',
        mx: '10px',
        mr: [0, '32px'],
        transition: 'width 0.3s',
        width: ['100%', '80%', '70%'],
        border: '1px solid rgba(0, 0, 0, 0.8)',
        '&:focus-within': {
          width: ['100%', '90%'],
        },
        // variant: 'containers.box',
      }}
    >
      <Button
        sx={{
          bg: 'transparent',
          display: 'flex',
          textAlign: 'center',
          img: {
            height: '20px',
            width: '20px',
          },
          pr: 1,
        }}
      >
        <img src={SearchIcon} alt="Search icon" srcSet="" />
      </Button>
      <Input
        sx={{
          outline: 'none',
          border: 'none',
          fontSize: 2,
          pr: 3,
          pl: 0,
          px: 1,
        }}
        placeholder={t('Search for ...')}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
