import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

const ogImageUrl = `${process.env.NEXT_PUBLIC_DOMAIN}/api/ogi`;

export default function HeadMeta(props) {
  const { description, url, title, image, keywords } = props;

  const ogQueries = [];

  if (title) {
    ogQueries.push({
      name: 'title',
      value: title,
    });
  }
  const favIconUrl =
    process.env.NEXT_PUBLIC_APP_URL_FAV_ICON ||
    'https://noticefax.com/favicon.ico?v=2';
  const { asPath } = useRouter();
  const { t } = useTranslation();

  const _url = url || `${process.env.NEXT_PUBLIC_DOMAIN}/${asPath}`;
  const _description = (description || t('SiteDescription'))
    .replace(/\s+/g, ' ')
    .trim();
  const _siteName = 'NoticeFax';
  const _author = 'NoticeFax';
  const _image =
    image ||
    `${ogImageUrl}?${ogQueries
      .map((item) => `${item.name}=${item.value}`)
      .join('&')}`;
  const _imageType = (_image || '').split('.').pop();
  const _title = `${title || 'Home'} | NoticeFax`.trim();
  const _twitterHandle = '@NoticeFax';
  const _keywords = `${
    keywords ? `${keywords},` : ''
  }Post, News, Media, Blog, News, Article, Share`.trim();

  return (
    <>
      <Head>
        <title>{_title}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
          key="viewport"
        />

        <link rel="icon" href={favIconUrl} />
        <meta name="keywords" content={_keywords} key="keywords" />
        <link rel="canonical" href={_url} />
        <meta property="og:site_name" content={_siteName}></meta>
        <meta name="description" content={_description} />
        <meta property="og:type" content="website" />
        {_author && <meta name="author" content={_author} key="author" />}

        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
        <meta name="twitter:site" content={_twitterHandle} key="twitter:site" />
        <meta name="twitter:title" content={_title} key="twitter:title" />
        <meta
          name="twitter:description"
          content={_description?.substring(0, 200)}
          key="twitter:description"
        />
        <meta name="twitter:image" content={_image} key="twitter:image" />

        {_image && (
          <meta
            property="og:image"
            itemProp="image"
            content={_image}
            key="og:image"
          />
        )}
        <meta property="og:title" content={_title} />
        <meta
          property="og:description"
          content={_description?.substring(0, 200)}
        />

        {/* {_image && _imageType && <meta property="og:image:type" content={`image/${_imageType}`} />} */}

        <link href="/robots.txt" />
      </Head>
    </>
  );
}
