/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IoLogoTwitter } from 'react-icons/io';

interface Props {
  facebookLink?: string;
  instagramLink?: string;
  twitterLink?: string;
  linkedinLink?: string;
  bg?: string;
  mr?: any;
}

const SocialLinks = ({
  facebookLink,
  instagramLink,
  twitterLink,
  linkedinLink,
  bg = 'primary',
  mr = [0],
}: Props) => {
  return (
    <div
      sx={{
        color: 'white',
        whiteSpace: 'nowrap' as any,
        a: {
          display: 'inline-block',
          color: 'inherit',
          bg,
          px: '9px',
          pt: '8px',
          pb: '4px',
          my: '8px',
          fontSize: '12px',
          borderRadius: '50%',
          '&:not(:last-child)': {
            mr: ['5px', '10px'],
          },
        },
        mr,
      }}
    >
      {facebookLink && (
        <a href={facebookLink} target="_blank" rel="noopener noreferrer">
          <FaFacebookF />
        </a>
      )}
      {instagramLink && (
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      )}
      {twitterLink && (
        <a href={twitterLink} target="_blank" rel="noopener noreferrer">
          <IoLogoTwitter />
        </a>
      )}
      {linkedinLink && (
        <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      )}
    </div>
  );
};

export default SocialLinks;
