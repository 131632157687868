import { createSelector } from 'reselect';

const convertToObject = (arr, key = 'postId') =>
  arr.reduce((obj, item) => {
    obj[item[key]] = true;
    return obj;
  }, {});

const selector = (state) => state.currentUser;

export const selectCurrentUser = createSelector([selector], (state) => state);
export const selectNotifications = createSelector(
  [selector],
  (state) => state.notifications,
);
export const selectIsAuthenticated = createSelector(
  [selector],
  (state) => state.isAuth,
);

export const selectConfirmAccountModal = createSelector(
  [selector],
  (state) => state.confirmAccountModal,
);

/**
 * User action selector
 */
export const selectBookmarked = createSelector([selector], (state) =>
  convertToObject(state.bookmarks),
);
export const selectLiked = createSelector([selector], (state) =>
  convertToObject(state.likes),
);
export const selectDisLiked = createSelector([selector], (state) =>
  convertToObject(state.disLikes),
);
export const selectCommented = createSelector([selector], (state) =>
  convertToObject(state.comments),
);
export const selectShared = createSelector([selector], (state) =>
  convertToObject(state.shared),
);
export const selectFollowed = createSelector([selector], (state) =>
  convertToObject(state.followers, 'followedId'),
);
export const selectCategoryLiked = createSelector([selector], (state) =>
  convertToObject(state.categoriesLikes, 'categoryId'),
);
