/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Label, Textarea } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { authorApplication } from 'redux/reducers/currentUser/actions';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';

const initialFormValues = {
  reason: '',
  type: 'Author Application',
};

export default function AuthorApplicationModal({ open, onClose }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [formValues, setFormValues] = useState(initialFormValues);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    setErrorMessage('');
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  const handleUpdate = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const submitData = () => {
    setSubmitting(true);
    authorApplication(formValues)(dispatch)
      .then((res) => {
        if (res) {
          onClose();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      contentLabel="Author Application Modal"
      width="350px"
      title={t('Author Application')}
    >
      {errorMessage ? <p>{errorMessage}</p> : null}
      <p>
        {t(
          'Your application will be worked on within 7 working days after subscription',
        )}
      </p>
      <Label mt="3" htmlFor="bio">
        {t('Reason')}
      </Label>
      <Textarea
        rows={5}
        name="reason"
        mb="3"
        value={formValues.reason}
        onChange={handleUpdate}
      />

      <div sx={{ textAlign: ['right'] }}>
        <Button onClick={submitData} loading={submitting}>
          {t('Apply')}
        </Button>
      </div>
    </Modal>
  );
}
