/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Button, Flex } from 'theme-ui';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { setAuthModal } from 'reducers/auth/actions';
import { logoutUser, fetchCurrentUser } from 'reducers/currentUser/actions';
import { selectCurrentUser } from 'reducers/currentUser/selectors';
import ProfileButton from './ProfileButton';
import LanguageDropdown from '../LanguageDropdown';
import { IoIosNotificationsOutline } from 'react-icons/io';
import AuthorApplicationModal from './AuthorApplicationModal';
import SearchBar from 'components/SearchBar';
import MobileMenu from './MobileMenu';
import NavWrapper from './NavWrapper';

const styles = {
  header: {
    width: '100vw',
    position: 'fixed' as any,
    py: 1,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 10,
    variant: 'containers.box',
    bg: 'white',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ['5px 0px', '5px 0px', '0px'],
    variant: 'containers.page',
    height: '100%',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative' as any,
  },
  logo: {
    height: ['40px', '50px'],
    bg: 'white',
  },
  humburger: {
    display: ['block', 'none'],
    position: 'absolute' as any,
    right: '0px',
    paddingTop: '5px',
    zIndex: 1,
    webkitUserSelect: 'none',
    userSselect: 'none',
    input: {
      display: 'block',
      width: '40px',
      height: '32px',
      position: 'absolute' as any,
      top: '-7px',
      left: '-5px',
      cursor: 'pointer',
      opacity: 0 /* hide this */,
      zIndex: 2 /* and place it over the hamburger */,

      webkitTouchCallout: 'none',

      ':checked ~ span': {
        opacity: 1,
        transform: 'rotate(45deg) translate(-2px, -2px)',
        bg: 'white',
      },
      ':checked ~ span:nth-last-of-type(2)': {
        opacity: 0,
        transform: 'rotate(0deg) scale(0.2, 0.2)',
      },
      ':checked ~ span:nth-last-of-type(1)': {
        transform: 'rotate(-45deg) translate(0, -1px)',
      },
      ':checked ~ div': {
        transform: 'none',
      },
    },
    span: {
      display: 'block',
      width: '33px',
      height: '4px',
      marginBottom: '5px',
      position: 'relative' as any,

      bg: 'primary',
      // color: 'primary',
      borderRadius: '3px',
      zIndex: 1,
      transformOrigin: '4px 0px',
      transition: `transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;`,
      ':last-of-type': {
        transformOrigin: '0% 0%',
      },
      ':nth-last-of-type(2)': {
        transformOrigin: '0% 100%',
      },
    },
  },
  mobileMenu: {
    position: 'fixed' as any,
    top: '-10px',
    left: 0,
    right: 0,
    px: '5%',
    pt: '25px',
    m: 0,
    bg: 'primary',
    webkitFontSmoothing: 'antialiased',

    transformOrigin: '0% 0%',
    transform: 'translate(-101%, 0)',

    transition: 'transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
    ul: {
      listStyleType: 'none',
      p: 0,
      li: {
        py: '1rem',
        fontSize: 3,
        a: {
          display: 'block',
          textDecoration: 'none',
          color: 'white',
          transition: 'color 0.3s ease',
          ':hover': {
            color: 'secondary',
          },
        },
      },
    },
  },
};

const NavIcon = ({ children, href }) => (
  <Link href={href}>
    <div
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        px: 2,
        position: 'relative',
      }}
    >
      {children}
    </div>
  </Link>
);

const SearchType = ({ type, title }: { type: string; title: string }) => {
  const { query, push, pathname } = useRouter();

  const onClick = () => {
    push({
      pathname,
      query: {
        ...(query || {}),
        type,
      },
    });
  };
  return (
    <div
      onClick={onClick}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        px: 3,
        py: 1,
        backgroundColor:
          query.type === type ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.03)',
        color: query.type === type ? 'textBlack' : 'textGray',
        mr: 2,
        borderRadius: 1,
        cursor: 'pointer',
      }}
    >
      {title}
    </div>
  );
};

const Nav = ({ cookies }: { cookies?: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [openAuthorApplication, setOpenAuthorApplication] = useState(false);
  const router = useRouter();
  const { isAuth, currentUser, signingIn, currentUserLoaded } =
    useSelector(selectCurrentUser);

  const { pathname } = useRouter();

  useEffect(() => {
    if (
      ((cookies && cookies['_nf_token']) ||
        localStorage?.getItem('_nf_token')) &&
      !currentUserLoaded
    ) {
      fetchCurrentUser()(dispatch);
    }

    // if (!localStorage?.getItem('_nf_token')) {
    //   dispatch(setAuthModal('Login'));
    // }
  }, []);

  useEffect(() => {
    if (openMenu) {
      setOpenMenu(false);
    }
  }, [router]);

  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [openMenu]);

  const openAuthModal = (auth = 'Login') => {
    dispatch(setAuthModal(auth));
    if (openMenu) {
      setOpenMenu(false);
    }
  };

  const renderCreatePost = () => {
    if (!currentUser?.firstName) return null;
    return (
      <Link href="/posts/create">
        <Button sx={{ mr: ['50px', '0px'] }}>{t('Create A Post')}</Button>
      </Link>
    );
    // return currentUser?.canPost ? (
    //   <Link href="/posts/create">
    //     <Button sx={{ mr: ['50px', '0px'] }}>{t('Create A Post')}</Button>
    //   </Link>
    // ) : (
    //   <Button
    //     onClick={() => setOpenAuthorApplication(true)}
    //     sx={{ mr: ['50px', '0px'] }}
    //   >
    //     {t('Register as an author')}
    //   </Button>
    // );
  };

  // const renderCreatePostMobile = () => {
  //   if (!currentUser?.firstName) return null;
  //   return (
  //     <li>
  //       <Link href="/posts/create">{t('Create A Post')}</Link>
  //     </li>
  //   );

  //   // return currentUser?.canPost ? (
  //   //   <li>
  //   //     <Link href="/posts/create">{t('Create A Post')}</Link>
  //   //   </li>
  //   // ) : (
  //   //   <li>
  //   //     <Button
  //   //       onClick={() => setOpenAuthorApplication(true)}
  //   //       sx={{ m: 0, p: 0 }}
  //   //     >
  //   //       {t('Register as an author')}
  //   //     </Button>
  //   //   </li>
  //   // );
  // };

  const renderAuthLinks = () => {
    if (!currentUser?.firstName) return null;

    return (
      <Flex
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          fontSize: 4,
        }}
      >
        {/* <Link href="/notifications"> */}
        <NavIcon href="/notifications">
          <IoIosNotificationsOutline />
          {currentUser?.notificationsCount > 0 && (
            <div
              sx={{
                position: 'absolute',
                top: '0px',
                right: '4px',
                transform: 'translateY(-50%)',
                fontSize: '10px',
                backgroundColor: 'textRed',
                border: '1px solid white',
                borderRadius: '50%',
                display: 'flex',
                height: '20px',
                width: '20px',
                color: 'white',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {currentUser?.notificationsCount}
            </div>
          )}
        </NavIcon>
        {/* </Link> */}
      </Flex>
    );
  };

  const searchTypes = [
    {
      type: 'post',
      title: t('Post'),
    },
    {
      type: 'people',
      title: t('People'),
    },
  ];

  const renderSearchFilters = () => {
    if (pathname !== '/search') return null;

    return (
      <div
        sx={{
          position: 'absolute',
          left: 0,
          top: '100%',
          right: 0,
          height: '60px',
          display: 'flex',
          backgroundColor: 'textWhite',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            variant: 'containers.page',
          }}
        >
          {searchTypes?.map((item) => (
            <SearchType key={item.type} type={item.type} title={item.title} />
          ))}
        </div>
      </div>
    );
  };
  return (
    <>
      <NavWrapper>
        {openAuthorApplication && (
          <AuthorApplicationModal
            open={openAuthorApplication}
            onClose={() => setOpenAuthorApplication(false)}
          />
        )}

        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'right',
          }}
        >
          <SearchBar />
        </div>
        <div sx={styles.right}>
          <div
            sx={{
              display: ['none', 'inline-flex', 'inline-flex'],
              alignItems: 'center',
              button: {
                minWidth: '100px',
              },
            }}
          >
            {renderCreatePost()}
            {renderAuthLinks()}
            {!isAuth && !cookies?._nf_token && <LanguageDropdown />}
            {currentUser?.firstName ? (
              <ProfileButton
                firstName={currentUser?.firstName}
                avatar={currentUser?.avatarUrl}
                username={currentUser?.username}
                onLogout={() => logoutUser()(dispatch)}
                signingIn={signingIn}
              />
            ) : (
              <>
                <Button
                  onClick={() => openAuthModal('Login')}
                  sx={{ mr: 3 }}
                  variant="outline"
                >
                  {t('Log in')}
                </Button>
                <Button onClick={() => openAuthModal('Signup')}>
                  {t('Sign up')}
                </Button>
              </>
            )}
          </div>

          {/* <div sx={styles.humburger} id="menuToggle" className="is-tablet">
              <input
                sx={{ opacity: 0 }}
                type="checkbox"
                checked={openMenu}
                onChange={({ target }) => setOpenMenu(!openMenu)}
              />
              <span></span>
              <span></span>
              <span></span>

              <div sx={styles.mobileMenu}>
                <div sx={{ display: 'flex' }}>
                  <img sx={styles.logo} src={Logo} alt="Logo" srcSet="" />
                </div>
                <div sx={{ display: 'flex' }}>
                  {currentUser?.avatarUrl && (
                    <img
                      sx={{
                        height: '40px',
                        width: '40px',
                        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.09)',
                        borderRadius: '50%',
                        mt: '20px',
                      }}
                      src={currentUser?.avatarUrl}
                      alt="user profile"
                      srcSet=""
                    />
                  )}
                </div>
                <ul sx={{ button: { m: 0, p: 0 } }}>
                  <li>
                    <Link href="/">{t('Home')}</Link>
                  </li>
                  <li>
                    <Link href="/categories">{t('Categories')}</Link>
                  </li>
                  <li>
                    <Link href="/authors">{t('Authors')}</Link>
                  </li>
                  {renderCreatePostMobile()}
                  {!currentUser.firstName ? (
                    <>
                      <li>
                        <Button onClick={() => openAuthModal('Login')}>
                          {t('Log in')}
                        </Button>
                      </li>
                      <li>
                        <Button onClick={() => openAuthModal('Signup')}>
                          {t('Sign up')}
                        </Button>
                      </li>
                    </>
                  ) : (
                    <li>
                      <Button onClick={() => logoutUser()(dispatch)}>
                        {t('Logout')}
                      </Button>
                    </li>
                  )}
                </ul>
              </div>
            </div> */}
        </div>
        {renderSearchFilters()}
      </NavWrapper>
      <MobileMenu currentUser={currentUser} />
    </>
  );
};

export default Nav;
