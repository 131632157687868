/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Button } from 'theme-ui';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

export default function LanguageDropdown() {
  const { locale, pathname, asPath, push } = useRouter();
  const [dropdown, setDropdown] = useState(false);

  const styles = {
    display: ['none', 'inline-flex'],
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 3,
    py: 2,
    px: 2,
    fontSize: 3,
    cursor: 'pointer',
    // '>img': {
    //   mr: 3,
    // },
    '>svg': {
      ml: 3,
    },
    zIndex: 1,
    mr: ['15px', '0px'],
  };

  const buttonStyle = {
    display: 'flex',
    fontSize: 2,
    alignItems: 'center',
    '&:hover': {
      color: 'primary',
    },
  };

  const flags = {
    fr: 'fr',
    en: 'gb',
  };

  const changeLanguage = (lang) => {
    if (lang !== locale) {
      push(pathname, asPath, { locale: lang });
    }
  };

  return (
    <div onClick={() => setDropdown(!dropdown)} sx={styles}>
      <Button sx={buttonStyle} variant="text">
        <img
          sx={{
            height: '18px',
            width: '20px',
            mr: 2,
            objectFit: 'cover',
            borderRadius: '50%',
          }}
          src={`/assets/countries/${flags[locale] || 'gb'}.svg`}
          alt="england flag"
          srcSet=""
        />
        {locale === 'fr' ? 'Français' : 'English'}

        {dropdown ? (
          <BsChevronUp css={{ marginLeft: '5px' }} />
        ) : (
          <BsChevronDown css={{ marginLeft: '5px' }} />
        )}
      </Button>

      <div
        sx={{
          position: dropdown ? 'fixed' : 'none',
          zIndex: -1,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
        onClick={() => setDropdown(false)}
      />

      <div
        sx={{
          position: 'absolute',
          display: dropdown ? 'block' : 'none',
          right: '15px',
          bg: 'textWhite',
          color: 'text',
          top: '100%',
          borderWidth: '1px',
          borderColor: 'secondary',
          borderStyle: 'solid',
          minWidth: '150px',
          mt: 1,
          borderRadius: 3,
          textAlign: 'left',
          a: {
            textDecoration: 'none',
            color: 'secondary',
            display: 'block',
            py: 2,
            px: 3,
            whiteSpace: 'nowrap',
            ':hover': {
              opacity: 0.8,
            },
          },
        }}
      >
        <Button
          sx={buttonStyle}
          variant="text"
          onClick={() => changeLanguage('en')}
        >
          <img
            sx={{
              height: '25px',
              width: '25px',
              mr: 1,
            }}
            src="/assets/countries/gb.svg"
            alt="england flag"
            srcSet=""
          />
          English
        </Button>
        <Button
          sx={buttonStyle}
          variant="text"
          onClick={() => changeLanguage('fr')}
        >
          <img
            sx={{
              height: '25px',
              width: '25px',
              mr: 1,
            }}
            src="/assets/countries/fr.svg"
            alt="england flag"
            srcSet=""
          />
          Français
        </Button>
      </div>
    </div>
  );
}
